var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('left-column')],1),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h2',{class:("card-title " + (_vm.loading ? 'skeleton skeleton-text text-h1' : ''))},[_vm._v(_vm._s(_vm.title))]),_c('table',{staticClass:"table border-bottom"},[_c('tbody',[_vm._l((_vm.posts),function(topic,i){return _c('tr',{key:("discussion-" + i)},[_c('td',{attrs:{"width":"15%"}},[_c('router-link',{attrs:{"to":{
                    name: 'trader.discussions.show',
                    params: { id: topic.id },
                  }}},[_c('img',{class:("avatar " + (_vm.loading ? 'skeleton' : '')),attrs:{"src":topic.author.avatar,"alt":topic.title}})])],1),_c('td',{staticClass:"border-right"},[_c('router-link',{attrs:{"to":{
                    name: 'trader.discussions.show',
                    params: { id: topic.id },
                  }}},[_c('h4',{class:("mb-0 " + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(topic.title)+" ")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : ''},[_c('small',[_vm._v(_vm._s(topic.date))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                    name: 'trader.discussions.show',
                    params: { id: topic.id },
                  }}},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Replies")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : ''},[_vm._v(" "+_vm._s(topic.comments)+" ")])])],1)])}),(_vm.posts.length == 0)?_c('tr',[_vm._m(0)]):_vm._e()],2)])])])]),_c('div',{staticClass:"col-md-2"},[_c('right-column')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"alert alert-warning"},[_vm._v("There is no data to display")])])}]

export { render, staticRenderFns }