<template>
  <div class="row">
    <div class="col-md-3">
      <left-column />
    </div>
    <div class="col-md-7">
      <div class="card">
        <div class="card-body">
          <h2 :class="`card-title ${loading ? 'skeleton skeleton-text text-h1' : ''}`">{{ title }}</h2>
          <table class="table border-bottom">
            <tbody>
              <tr v-for="(topic, i) in posts" :key="`discussion-${i}`">
                <td width="15%">
                  <router-link
                    :to="{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }"
                  >
                    <img
                      :src="topic.author.avatar"
                      :alt="topic.title"
                      :class="`avatar ${loading ? 'skeleton' : ''}`"
                    />
                  </router-link>
                </td>
                <td class="border-right">
                  <router-link
                    :to="{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }"
                  >
                    <h4
                      :class="`mb-0 ${loading ? 'skeleton skeleton-text' : ''}`"
                    >
                      {{ topic.title }}
                    </h4>
                    <p :class="loading ? 'skeleton skeleton-text' : ''">
                      <small>{{ topic.date }}</small>
                    </p>
                  </router-link>
                </td>
                <td width="10%">
                  <router-link
                    :to="{
                      name: 'trader.discussions.show',
                      params: { id: topic.id },
                    }"
                  >
                    <p class="mb-0 text-black">Replies</p>
                    <p :class="loading ? 'skeleton skeleton-text' : ''">
                      {{ topic.comments }}
                    </p>
                  </router-link>
                </td>
              </tr>
              <tr v-if="posts.length == 0">
                <td>
                  <div class="alert alert-warning">There is no data to display</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <right-column />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      title: "",
      posts: [
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
        { author: {} },
      ],
      loading: true
    };
  },

  methods: {
    fetch() {
      this.$axios
        .get(`/api/v1/trader/categories/${this.$route.params.id}`)
        .then((response) => {
          this.title = response.data.title;
          this.posts = response.data.posts;
          this.loading = false
        });
    },
  },
};
</script>
